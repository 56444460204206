@import "../../styles/base.scss";
.contact_box {
  text-align: center;
}
.contact {
  display: flex;
  font-family: Roboto-Thin;
  @include breakpoint(medium) {
    flex-direction: column;
  }
}

.information,
.address,
.openHours {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: wrap;
  max-width: 300px;
  padding: 36px;
}

.information p,
.address p,
.openHours p {
  margin: 4px;
  text-align: left;
}

button {
  margin-top: 36px;
}

a {
  text-decoration: none;
  color: black;
  padding: 7px;
}

.implementation {
  font-family: Roboto-Thin;
  font-size: 10px;
  text-align: right;
  background-color: #f8ecf0;
  padding: 8px;
}
