@import "../../styles/base.scss";
.terms {
  font-family: Roboto-Thin;
  line-height: 30px;
  padding-top: 64px;
}
.background_feather {
  display: block;
  padding: 48px;
}

.terms p {
  padding-bottom: 8px;
  padding-top: 8px;
}
.termsHeader{ 
  font-size: 36px;
  text-align: center;
  padding-bottom: 81px;
}
.paragraph h3{
  text-align: center;
}
.paragraph {
  padding-top: 16px;
  padding-bottom: 16px;
}
.alpha{
  list-style: lower-alpha;

}
