@import "../../styles/base.scss";

//.aboutUs {
//  display: flex;
//  padding-left: 36px;
//  justify-content: space-around;
//  align-items: center;
//  @include breakpoint(small) {
//    flex-direction: column;
//    align-items: baseline;
//  }
//}
//.aboutUs_next {
//  display: flex;
//  padding-right: 64px;
//  flex-direction: row-reverse;
//  @include breakpoint(small) {
//    flex-direction: column;
//    align-items: baseline;
//    padding-left: 16px;
//    padding-right: 0px;
//  }
//}
.aboutUs {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 400px;
  padding: 48px;
    @include breakpoint(small) {
      width: auto;
  }
}
.photo {
  width: 100%;
  height: auto;
}

.photo img {
  width: auto;
  height: 300px;
  border-bottom-left-radius: 7%;
  border-top-right-radius: 7%;
  border: 3px solid #fff;
}

//.photo:before {
//  padding: 16px;
//  content: '';
//  background-color: #f8ecf0;
//  position: absolute;
//  width: 100%;
//  z-index: -2;
//  left: 0;
//  right: 0;
//}

.about_inf h3 {
  font-family: Roboto-Regular;
  text-align: left;
  @include breakpoint(small) {
    margin-top: 16px;
  }
}
.about_inf p {
  font-family: Roboto-Thin;
  margin: 4px;
  text-align: left;
  line-height: 30px;
  @include breakpoint(small) {
    padding-right: 16px;
  }
}

.aboutUsWrapper {
  display: flex;
  justify-content: space-around;
  @include breakpoint(medium) {
    flex-direction: column;
    align-items: center;
  }
}
