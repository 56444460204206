@import "../../styles/base.scss";
.modalBox{
  position: absolute;
  left: 10vw;
  right: 10vw;
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  height: auto;
  background-color: #f8ecf0;
  padding: 48px;
  font-size: 24px;
  font-family: Roboto-Thin;
  line-height: 30px;
  border-bottom-left-radius: 7%;
  border-top-right-radius: 7%;
  border: 3px solid #fff;
}

.modalBox p {
  margin-bottom: 16px;
}

.modalBox img {
  width: 32px;
  height: 32px;
  padding: 2px;
}
