@import "../../styles/base.scss";

.card h3 {
  font-family: Roboto-Thin;
  text-transform: uppercase;
}

.card p {
  padding-bottom: 8px;
  padding-right: 8px;
  font-family: Roboto-Thin;
}
.card {
  width: 180px;
  text-align: center;
  padding: 36px;
  border-bottom-left-radius: 7%;
  border-top-right-radius: 7%;
  border: 3px solid #fff;
  @include breakpoint(medium) {
    width: 280px;
  }
}

.card:hover {
  background-color: #fff;
  border-bottom-left-radius: 7%;
  border-top-right-radius: 7%;
  box-shadow: 10px 5px 5px #fff;
}
.card img {
  width: 64px;
  height: 100%;
}
.offers {
  padding-top: 64px;
  padding-bottom: 64px;
  text-align: center;
}
.offers_box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  @include breakpoint(small) {
    flex-direction: column;
  }
  @include breakpoint(medium) {
    flex-wrap: wrap;
  }
}
.offersWrapper {
  background-color: #f8ecf0;
  margin-top: 36px;
}
