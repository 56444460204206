@import "mixins";
@import "fonts";

.wrapper {
  max-width: 1170px;
  margin: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.background_feather {
  display: block;
  position: relative;
  z-index: -200; 
  display: flex;
  justify-content: center;
}

.background_feather::after {
  content: "";
  background: url("../images/feather.jpg");
  background-position: center;
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  @include breakpoint(small) {
    background-position: top;
  }
}

h2 {
  text-align: center;
  font-family: Roboto-Thin;
  font-size: 36px;
  padding-top: 64px;
  padding-bottom: 64px;
}

button:hover {
  background-color: #dcdadd;
}

.seperator {
  border-bottom: 1px solid #dcdadd;
  width: 100%;
}
