@mixin breakpoint($point) {
  @if $point == small {
    @media (max-width: 475px) {
      @content;
    }
  } @else if $point == medium {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point == maxLarge {
    @media (min-width: 1500px) {
      @content;
    }
  }
}
