@import "../../styles/base.scss";
nav {
  position: absolute;
  align-self: flex-start;
  width: 100%;
}

nav a {
  padding-top: 8px;
}
nav img {
  width: 32px;
  height: 32px;
  padding: 2px;
}

.socialNav {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.socialIcons {
  display: flex;
  flex-direction: row;
  padding-right: 80px;
}
.menuIcon {
  padding-left: 16px;
}
.navigationItems {
  display: flex;
  flex-direction: row;
  width: 735px;
  list-style: none;
  justify-content: center;
  padding-top: 8px;
  font-size: 20px;
  text-transform: uppercase;
  font-family: Roboto-Thin;
  top: 0;
  left: 100px;
  position: fixed;
  z-index: 20;
  overflow: hidden;
  text-align: right;
  @include breakpoint(medium) {
    justify-content: flex-start;
    background-color: #f8ecf0;
    box-shadow: 0 0 20px 10px #fff;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 400px;
    width: 100%;
    height: 100%;
  }
}
.navigationItems img {
  align-self: flex-end;
  margin-bottom: 64px;
  padding-right: 32px;
}
.navigationItems li a {
  font-size: 20px;
  margin: 16px;
  font-family: Roboto-Thin;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}
.navigationItems a {
  @include breakpoint(medium) {
    border: 2px solid #fff;
    width: 80vw;
    text-align: center;
    margin-top: 40px;
  }
}

.navigationItems a a {
  @include breakpoint(medium) {
    border: none;
  }
}

.navigationItems > li:hover {
  background-color: transparent;
}

.disable {
  display: none;
}

.wrapperNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;
  margin-left: -40px;
  margin-right: -40px;
}

.wrapperNavActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -16px;
  margin-left: -40px;
  margin-right: -40px;
  background-color: #f8ecf0;
  border-bottom: 1px solid #fff;
}
