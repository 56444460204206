@import "../../styles/base.scss";

.permanentMakeupSection p {
  font-family: Roboto-Thin;
  font-size: 1.2em;
  line-height: 28px;
  margin-right: 16px;
}
.permanentDescr{
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.permanentMakeupSection h2 {
  text-align: center;
  margin: 0 24px 0 24px;
}
.permanentMakeupSection button {
  background-color: #f8ecf0;
  margin: auto;
  color: #001f3f;
  padding: 16px;
  border-bottom-left-radius: 7%;
  border-top-right-radius: 7%;
  border: 3px solid #f8ecf0;
}

.permanentImages {
  display: flex;
  margin-left: 32px;
  flex-wrap: wrap;

  @include breakpoint(medium) {
   justify-content: center;
  }
}

.permanentImages img {
  border-bottom-left-radius: 7%;
  border-top-right-radius: 7%;
  border: 3px solid #fff;
}

.permanentImages div {
  padding: 16px;
  padding-left: 0;
}
