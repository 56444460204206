@import "../../styles/base.scss";

.background {
  background: url("../../images/evie.jpg");
  align-items: center;
  background-position: bottom;
  background-size: cover;
  left: 0;
  top: 0;
  bottom: 0;
  width: 108vw;
  height: 750px;

  display: flex;
  justify-content: flex-start;
}

h1 {
  font-family: Roboto-Thin;
  line-height: 1em;
  font-size: 64px;
  @include breakpoint(small) {
    font-size: 40px;
    letter-spacing: 2px;
  }
}

.header p {
  font-family: Roboto-Thin;
  font-size: 24px;
  max-width: 800px;
}

.headerSection {
  display: flex;
}

.header {
  margin-left: 32px;
  @include breakpoint(small) {
    margin-left: 50px;
    margin-right: 20px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include breakpoint(medium) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.buttons button {
  margin-right: 16px;
  width: 250px;
}
