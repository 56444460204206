@font-face {
  font-family: "Heebo-Medium";
  src: url("../fonts/Heebo-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Courgette-Regular";
  src: url("../fonts/Courgette-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("../fonts/Roboto-Thin.ttf");
}
