button {
  transition: all 0.5s ease;
  color: rgb(10, 1, 1);
  border: 1px solid rgb(8, 1, 1);
  font-family: "Roboto-Regular";
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  font-size: 18px;
  background-color: transparent;
  padding: 10px;
  outline: none;
  border-radius: 1px;
  &:hover {
    color: #001f3f;
    background-color: #fff;
  }
}
