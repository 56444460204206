@import "../../styles/base.scss";

.imagesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.imagesSection {
  padding-top: 64px;
}
.images {
  padding: 8px;
  object-fit: cover

}

.title {
  text-align: center;
  font-size: 32px;
  font-family: Roboto-Thin;
}
