@import "../../styles/base.scss";

.category {
  padding: 8px;
  font-family: Roboto-Thin;
}
.categoryTitle {
  display: flex;
  text-transform: uppercase;
  height: 100px;
}
.firstCategoryTitle {
  margin-top: 64px;
  display: flex;
  text-transform: uppercase;
  height: 100px;
}
.firstCategoryTitle img {
  width: 32px;
  margin-right: 8px;
}
.categoryTitle img {
  width: 32px;
  margin-right: 8px;
}
.category h3 {
  text-align: left;
  font-size: 32px;
}
.category h4 {
  text-align: left;
  font-weight: bolder;
  font-size: 24px;
}

.categoryDetails {
  display: flex;
  flex-direction: row;
}

.categoryInfo {
  padding-right: 64px;
  max-width: 800px;
  border-right: 3px solid #dcdadd;
  width: 75%;

  @include breakpoint(medium) {
    padding-right: 16px;
  }
}

.categoryPrice {
  padding-left: 64px;
  display: inline-block;
  align-self: center;
  @include breakpoint(small) {
    padding-left: 16px;
  }
}
